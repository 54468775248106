import { environment } from "@/config";
import messaging from "../../main";

let timer;

export default {
  async login(context, payload) {
    const response = await fetch(`${environment.apiUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: payload.login,
        password: payload.password,
      }),
    });

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(
        responseData.message || "Ошибка логина, проверьте входные данные"
      );
    }

    const expiresIn = +responseData.expires_in * 1000;
    const expirationDate = new Date().getTime() + expiresIn;

    // timer = setTimeout(function () {
    //   context.dispatch("autoLogout");
    // }, expiresIn);
    timer = setInterval(() => {
      context.dispatch("autoRefreshTokens");
    }, 1000 * 60 * 20);

    // id юзера не будет. всё по токену
    localStorage.setItem("access_token", responseData.access_token);
    localStorage.setItem("refresh_token", responseData.refresh_token);
    localStorage.setItem("tokenExpiration", expirationDate);

    context.commit("setUserAfterLogin", {
      token: responseData.access_token,
      refresh_token: responseData.refresh_token,
      roles: responseData.roles,
      username: responseData.username,
    });

    if (await Notification.requestPermission()) {
      Notification.requestPermission()
        .then(function () {
          return messaging.getToken();
        })
        .then(function (token) {
          console.log("token passed");
          fetch(`${environment.apiUrl}/push/token`, {
            headers: {
              Authorization: `Bearer ${responseData.access_token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              token: token,
            }),
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  },

  async tryLogin(context) {
    let token = localStorage.getItem("access_token");
    let refresh_token = localStorage.getItem("refresh_token");
    let tokenExpiration = localStorage.getItem("tokenExpiration");

    if (!(token && token !== "undefined")) {
      context.dispatch("logout");
      return;
    }

    let expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn < 0) {
      // обновим токен
      // console.log("token expired, go refresh", token);
      const response = await fetch(`${environment.apiUrl}/oauth/access_token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          grant_type: "refresh_token",
          refresh_token: refresh_token,
        }),
      });
      // console.log("got new token");
      const responseData = await response.json();
      // if (response.status !== 200) {
      //   throw new Error(
      //     responseData.message || "Ошибка логина, проверьте входные данные"
      //   );
      // }

      // console.log("got new token from answer", responseData.access_token);
      expiresIn = +responseData.expires_in * 1000;
      const expirationDate = new Date().getTime() + expiresIn;

      // id юзера не будет. всё по токену
      localStorage.setItem("access_token", responseData.access_token);
      localStorage.setItem("refresh_token", responseData.refresh_token);
      localStorage.setItem("tokenExpiration", expirationDate);

      token = responseData.access_token;
      refresh_token = responseData.refresh_token;

      console.log("set data here with new token", responseData);
      context.commit("setUserAfterLogin", {
        token: responseData.access_token,
        refresh_token: responseData.refresh_token,
        roles: responseData.roles,
        username: responseData.username,
      });
    }

    // timer = setTimeout(function () {
    //   context.dispatch("autoLogout");
    // }, expiresIn);

    if (token) {
      // console.log("Good token, lets go");
      context.commit("setUserTokens", {
        token,
        refresh_token,
      });

      timer = setInterval(() => {
        context.dispatch("autoRefreshTokens");
      }, 1000 * 60 * 20);
    } else {
      context.dispatch("logout");
    }
  },

  async autoRefreshTokens(context) {
    let refresh_token = localStorage.getItem("refresh_token");
    const response = await fetch(`${environment.apiUrl}/oauth/access_token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        grant_type: "refresh_token",
        refresh_token: refresh_token,
      }),
    });
    const responseData = await response.json();

    const expiresIn = +responseData.expires_in * 1000;
    const expirationDate = new Date().getTime() + expiresIn;

    localStorage.setItem("access_token", responseData.access_token);
    localStorage.setItem("refresh_token", responseData.refresh_token);
    localStorage.setItem("tokenExpiration", expirationDate);

    const token = responseData.access_token;
    refresh_token = responseData.refresh_token;

    context.commit("setUserTokens", {
      token,
      refresh_token,
    });
  },

  async getUserMe(context) {
    let response = await fetch(`${environment.apiUrl}/me`, {
      headers: {
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });

    await context.commit("setUserAfterMe", await response.json());

    response = await fetch(
      `${environment.accessManagerUrl}/roles/${context.getters.getRole}`,
      {
        headers: {
          Authorization: `Basic ${btoa(
            `${environment.accessManagerLogin}:${environment.accessManagerPassword}`
          )}`,
          "Content-Type": "application/json",
        },
      }
    );
    let permissions = await response.json();
    let permissionMap = {};
    for (const permission of permissions.permissions) {
      permissionMap[permission.function.code] = permission.has;
    }
    context.commit("setUserPermissions", permissionMap);
  },

  async loadRoles(context) {
    const response = await fetch(`${environment.apiUrl}/roles`, {
      headers: {
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });

    context.commit("setRoleList", await response.json());
  },

  logout(context) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("employeeCurrentRegion");
    localStorage.removeItem("employeeCurrentCompany");

    sessionStorage.clear();

    context.commit("setCurrentUserRegions", []);
    context.commit("setCurrentTechnicProducers", []);

    context.commit("clearUserData");
    // clearTimeout(timer);
    clearInterval(timer);

    context.commit("setUserTokens", {
      token: null,
      refresh_token: null,
    });
  },

  async loadAll(context) {
    await Promise.all([
      context.dispatch("data/loadConfig"),
      context.dispatch("getUserMe"),
      context.dispatch("loadRoles"),
      context.dispatch("data/loadRegions"),
      context.dispatch("data/loadCountries"),
      context.dispatch("data/loadTechnicTypes"),
      context.dispatch("data/loadWorkTypes"),
      context.dispatch("myprofiles/loadClients"),
      // подгружаются для списка
      context.dispatch("data/loadTechnicModels"),
      context.dispatch("data/loadTechnicProducers"),
      //TODO Посмотреть в каких компонентах можно выпилить эту подгрузку, если она не используется для обновления
      context.dispatch("myprofiles/loadEmployes"),
      context.dispatch("data/loadStatuses"),
      context.dispatch("data/loadChecklistTemplates"),
      context.dispatch("myprofiles/loadOnlyEngineers"),
    ]);
  },

  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },
};
