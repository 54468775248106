import { createStore } from "vuex";

import authModule from "./auth/index";
import myProfileModule from "./mypfofiles/index";
import dataModule from "./data/index";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  modules: {
    auth: authModule,
    myprofiles: myProfileModule,
    data: dataModule,
  },
  plugins: [
    createPersistedState({
      paths: ["auth.currentUserRegions", "auth.currentTechnicProducers"],
    }),
  ],
});

export default store;
