import { apiConfig } from "@/api/apiConfig";
import moment from "moment";

export default {
  getEngineers() {
    return apiConfig.get("/engineers");
  },

  getUsers(params = {}) {
    return apiConfig
      .get("/users", {
        params: params,
      })
      .catch((error) => {
        throw new Error(
          error.response.data.message || "Ошибка при загрузке пользователей!"
        );
      });
  },

  createUser(data) {
    return apiConfig.post("/users", data).catch((error) => {
      throw new Error(
        error.response.data.message || "Ошибка создания пользователя!"
      );
    });
  },

  updateUser(data) {
    return apiConfig.put(`/users/${data.id}`, data).catch((error) => {
      throw new Error(
        error.response.data.message || "Ошибка обновления пользователя!"
      );
    });
  },

  getAttendanceLog(date) {
    const formattedDate = moment(date).format("YYYY-MM-DD");

    return apiConfig.get(`/users/workDays`, {
      params: {
        fromDay: formattedDate,
        toDay: formattedDate,
      },
    });
  },

  setWorkDaysByIds(data) {
    return apiConfig.put(`/users/workDays`, { workDays: data });
  },
};
