<template>
  <div @click="goTop" class="arrow" ref="arrow">
    <i class="pi pi-arrow-circle-up arrow-size"></i>
  </div>
</template>

<script>
export default {
  name: "ScrollTopArrow",
  mounted() {
    document.addEventListener("scroll", this.scrollPosition);
  },
  unmounted() {
    document.removeEventListener("scroll", this.scrollPosition);
  },
  methods: {
    goTop() {
      if (
        document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0
      ) {
        window.scrollBy(0, -50);
        setTimeout(this.goTop, 10);
      }
    },
    scrollPosition() {
      let scrolled = window.pageYOffset || document.documentElement.scrollTop;
      if (scrolled < 500) {
        this.$refs.arrow.style.display = "none";
      } else {
        this.$refs.arrow.style.display = "block";
      }
    },
  },
};
</script>

<style scoped>
.arrow {
  position: fixed;
  display: none;
  right: 20px;
  bottom: 50px;
  cursor: pointer;
  color: green;
}

.arrow-size {
  font-size: 40px;
}
</style>
