export const ROLES_LIST = {
  ADMIN: "Администратор",
  OPERATOR: "Оператор",
  PURCHASING_MANAGER: "Менеджер по закупке запасных частей",
  EMPLOYEE_OF_CLIENT: "Сотрудник клиента",
  ENGINEER: "Инженер",
  CHIEF_ENGINEER: "Шеф-инженер",
  TECHNICAL_SUPPORT_ENGINEER: "Инженер технической поддержки",
  HEAD_OF_SERVICE: "Руководитель сервиса",
  SALES_MANAGER: "Менеджер по продажам",
  WARRANTY_ENGINEER: "Инженер по гарантии",
  MAIN_OPERATOR: "Главный оператор",
  STELLAR_WARRANTY_ENGINEER: "Инженер по гарантии + Stellar",
  MAIN_SALES_MANAGER: "Начальник отдела продаж",
  STELLAR_EMPLOYEE: "Сотрудник Stellar"
};

// пусть будет полный список, так-то он по апи получается
export const ROLES_LIST_SELECT = [
  { name: "Менеджер по продажам", value: "SALES_MANAGER" },
  { name: "Администратор", value: "ADMIN" },
  { name: "Оператор", value: "OPERATOR" },
  { name: "Менеджер по закупке запасных частей", value: "PURCHASING_MANAGER" },
  { name: "Инженер по гарантии + Stellar", value: "STELLAR_WARRANTY_ENGINEER" },
  { name: "Сотрудник клиента", value: "EMPLOYEE_OF_CLIENT" },
  { name: "Инженер", value: "ENGINEER" },
  { name: "Шеф-инженер", value: "CHIEF_ENGINEER" },
  {
    name: "Инженер технической поддержки",
    value: "TECHNICAL_SUPPORT_ENGINEER",
  },
  { name: "Руководитель сервиса", value: "HEAD_OF_SERVICE" },
  { name: "Инженер по гарантии", value: "WARRANTY_ENGINEER" },
  { name: "Главный оператор", value: "MAIN_OPERATOR" },
  { name: "Начальник отдела продаж", value: "MAIN_SALES_MANAGER" },
  { name: "Сотрудник Stellar", value: "STELLAR_EMPLOYEE" },
];

export function getRoleText(role) {
  return ROLES_LIST[role] ?? "";
}

export const ROLE = {
  ADMIN: "ADMIN",
  OPERATOR: "OPERATOR",
  PURCHASING_MANAGER: "PURCHASING_MANAGER",
  EMPLOYEE_OF_CLIENT: "EMPLOYEE_OF_CLIENT",
  ENGINEER: "ENGINEER",
  STELLAR_WARRANTY_ENGINEER: "STELLAR_WARRANTY_ENGINEER",
  CHIEF_ENGINEER: "CHIEF_ENGINEER",
  TECHNICAL_SUPPORT_ENGINEER: "TECHNICAL_SUPPORT_ENGINEER",
  HEAD_OF_SERVICE: "HEAD_OF_SERVICE",
  SALES_MANAGER: "SALES_MANAGER",
  WARRANTY_ENGINEER: "WARRANTY_ENGINEER",
  MAIN_OPERATOR: "MAIN_OPERATOR",
  MAIN_SALES_MANAGER: "MAIN_SALES_MANAGER",
  STELLAR_EMPLOYEE: "STELLAR_EMPLOYEE",
};

export const ROLES_LIST_TASK_STATUSES = [
  { value: "NEW", name: "Новая" },
  { value: "WAIT_ENGINEER_CONFIRM", name: "Ожидает подтверждения инженером" },
  { value: "WORK_ENGINEER", name: "В работе" },
  { value: "RETURNED", name: "Возвращена" },
  { value: "WAIT_CLIENT_CONFIRM", name: "Ожидает подтверждения клиентом" },
  { value: "WAIT_OPERATOR_CONFIRM", name: "Ожидает подтверждения оператором" },
  { value: "DONE", name: "Отработано" },
  { value: "CANCELED", name: "Отменено" },
  { value: "PURCHASING_PARTS", name: "Закупка запчастей" },
  { value: "DONE_NO_SO", name: "Отработано без С.О." },
];

export const ROLES_LIST_CHANGE_TASK_STATUSES = [
  { value: "WORK_ENGINEER", name: "В обработке" },
  { value: "WAIT_ENGINEER_CONFIRM", name: "Ожидает подтверждения инженером" },
  { value: "RETURNED", name: "Возвращена" },
  { value: "WAIT_CLIENT_CONFIRM", name: "Ожидает подтверждения клиентом" },
  { value: "WAIT_OPERATOR_CONFIRM", name: "Ожидает подтверждения оператором" },
  { value: "DONE", name: "Отработано" },
  { value: "CANCELED", name: "Отменено" },
  { value: "PURCHASING_PARTS", name: "Закупка запчастей" },
];

export const TASK_STATUSES = {
  NEW: "Новая",
  WAIT_ENGINEER_CONFIRM: "Ожидает подтверждения инженером",
  WORK_ENGINEER: "В работе",
  RETURNED: "Возвращена",
  WAIT_CLIENT_CONFIRM: "Ожидает подтверждения клиентом",
  WAIT_OPERATOR_CONFIRM: "Ожидает подтверждения оператором",
  DONE: "Отработано",
  CANCELED: "Отменено",
  PURCHASING_PARTS: "Закупка запчастей",
  DONE_NO_SO: "Отработано без С.О.",
};

export const CHECKLIST_ITEMS_TYPES = [
  { name: "Текст", value: "TEXT" },
  { name: "Переключатель", value: "SWITCH" },
  { name: "Файл", value: "FILE" },
  { name: "Файлы", value: "FILES" },
];

export function getTaskStatusText(status) {
  return TASK_STATUSES[status] ?? "";
}
