import { environment } from "@/config";

export default {
  async loadConfig(context) {
    let response = await fetch(`${environment.apiUrl}/filters`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    response = await response.json();
    context.commit("setConfig", response);
    context.commit("setFiltersCount", response.pages.length);
  },
  async loadStatuses(context) {
    const response = await fetch(`${environment.apiUrl}/statuses`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    context.commit("setStatuses", await response.json());
  },
  async loadRegions(context) {
    const response = await fetch(`${environment.apiUrl}/regions`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    context.commit("setRegions", await response.json());
  },

  async loadCountries(context) {
    const response = await fetch(`${environment.apiUrl}/countries`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    context.commit("setCountries", await response.json());
  },

  async loadTechnicTypes(context) {
    const response = await fetch(`${environment.apiUrl}/technic_types`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    context.commit("setTechnicTypes", await response.json());
  },

  async loadWorkTypes(context) {
    const response = await fetch(`${environment.apiUrl}/work_types`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    context.commit("setWorkTypes", await response.json());
  },

  async loadTechnicModels(context) {
    const response = await fetch(`${environment.apiUrl}/technic_models`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    context.commit("setTechnicModels", await response.json());
  },

  async loadTechnicProducers(context) {
    const response = await fetch(`${environment.apiUrl}/technic_producers`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    context.commit("setTechnicProducers", await response.json());
  },

  async loadChecklistTemplates(context) {
    const response = await fetch(`${environment.apiUrl}/checklist_templates`, {
      headers: {
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
    });
    context.commit("setChecklistTemplates", await response.json());
  },

  setTaskFiles(context, data) {
    context.commit("setTaskFiles", data);
  },

  pushNewTaskFile(context, data) {
    context.commit("updateTaskFiles", data);
  },
  deleteFileFromState({ commit, getters }, data) {
    let files = getters.taskFiles.filter((f) => {
      return f.id !== data.id;
    });
    commit("setTaskFiles", files);
  },
};
