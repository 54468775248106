<template>
  <div class="p-d-flex p-flex-row p-ai-center p-jc-center p-column-filter">
    <DatePicker v-model="dateRange.start">
      <template v-slot="{ inputValue, inputEvents }">
        <div class="p-d-flex p-flex-row p-ai-center">
          <InputText :value="inputValue" v-on="inputEvents" />
        </div>
      </template>
    </DatePicker>

    <i class="pi pi-arrow-right p-mr-1 p-ml-1" />

    <DatePicker v-model="dateRange.end">
      <template v-slot="{ inputValue, inputEvents }">
        <div class="p-d-flex p-flex-row p-ai-center">
          <InputText :value="inputValue" v-on="inputEvents" />
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { Calendar, DatePicker } from "v-calendar";
export default {
  name: "RangeDatePicker",
  props: ["range"],
  components: {
    Calendar,
    DatePicker,
  },
  computed: {
    dateRange() {
      return this.range;
    },
  },
  watch: {
    //TODO Почему то отрабатывает 3 раза, разобраться
    dateRange: {
      handler(newVal) {
        this.$emit("update:range", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
