import axios from "axios";
import { environment } from "@/config";

export const apiConfig = axios.create({
  baseURL: environment.apiUrl,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    "X-Agro-Client": "Web",
  },
});
