<template>
  <i
    class="pi pi-circle-on"
    :class="{
      blue: blue,
      green: green,
      orange: orange,
      red: red,
    }"
  ></i>
</template>

<script>
export default {
  name: "StatusColor",
  props: ["status", "clientTask"],
  computed: {
    red() {
      return this.clientTask;
    },
    blue() {
      return (
        this.status === "NEW" ||
        this.status === "WAIT_ENGINEER_CONFIRM" ||
        this.status === "RETURNED" ||
        this.status === "DONE_NO_SO" ||
        this.status === "CANCELED"
      );
    },
    orange() {
      return (
        this.status === "WORK_ENGINEER" || this.status === "PURCHASING_PARTS"
      );
    },
    green() {
      return (
        this.status === "WAIT_OPERATOR_CONFIRM" ||
        this.status === "WAIT_CLIENT_CONFIRM" ||
        this.status === "DONE"
      );
    },
  },
};
</script>

<style scoped>
.pi {
  margin-right: 0.5rem;
}
.red {
  color: red !important;
}
.orange {
  color: orange;
}
.yellow {
  color: yellow;
}
.green {
  color: green;
}
.blue {
  color: #7289da;
}
</style>
