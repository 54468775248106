export default {
  setFiltersCount(state, payload) {
    state.filtersCount = payload
  },
  setConfig(state, payload) {
    state.config = payload;
  },
  setChecklistTemplates(state, payload) {
    state.checklistTemplates = payload;
  },
  setRegions(state, payload) {
    state.regions = payload;
  },
  setCountries(state, payload) {
    state.countries = payload;
  },
  setTechnicTypes(state, payload) {
    state.technicTypes = payload;
  },
  setStatuses(state, payload) {
    state.statuses = payload;
  },
  setChecklistTypes(state, payload) {
    state.checklistTypes = payload;
  },
  setWorkTypes(state, payload) {
    state.workTypes = payload;
  },
  setTechnicModels(state, payload) {
    state.technicModels = payload;
  },
  setTechnicProducers(state, payload) {
    state.technicProducers = payload;
  },
  setTaskFiles(state, payload) {
    state.taskFiles = payload;
  },
  updateTaskFiles(state, payload) {
    state.taskFiles.push(payload);
  },
};
