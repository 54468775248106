import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      token: null,
      refresh_token: null,
      username: null,
      role: null,
      id: null,
      permissions: {},
      didAutoLogout: false,
      userData: {},
      taskFilterText: null,
      doneAuthTokens: false,
      userRegions: [],
      currentUserRegions: [],
      currentTechnicProducers: [],
      userCompanies: [],
    };
  },
  mutations,
  actions,
  getters,
};
