import { apiConfig } from "@/api/apiConfig";

export default {
  getTechnics(params = {}) {
    return apiConfig
      .get("/technics", {
        params: params,
      })
      .catch((error) => {
        throw new Error(
          error.response.data.message || "Ошибка получения техники!"
        );
      });
  },
};
