import { getRoleText, ROLE } from "@/models/roles";
import usersClient from "@/api/usersClient";
import companiesClient from "@/api/companiesClient";
import tasksClient from "@/api/tasksClient";
import technicsClient from "@/api/technicsClient";

export default {
  //TODO Посмотреть где можно этот метод юзать вместо фильтрации
  async loadOnlyEngineers(context) {
    const responseData = (await usersClient.getEngineers()).data;
    context.commit(
      "setOnlyEngineers",
      responseData.map((u) => {
        return {
          ...u,
          nameText: `${u.lastname} ${u.firstname} [${getRoleText(u.role)}]`,
        };
      })
    );
  },
  async loadEmployes(context) {
    const sortEngineers = (a, b) => {
      if (a.nameText < b.nameText) {
        return -1;
      }

      if (a.nameText > b.nameText) {
        return 1;
      }

      return 0;
    };

    const responseData = (
      await usersClient.getUsers({
        size: 1,
        page: 0,
      })
    ).data;

    const roleData = responseData.map((u) => {
      return { ...u, roleText: getRoleText(u.role) };
    });

    context.commit("setEmployes", roleData);
    context.commit(
      "setEngineers",
      roleData
        .filter(
          (u) =>
            u.role === ROLE.TECHNICAL_SUPPORT_ENGINEER ||
            u.role === ROLE.ENGINEER ||
            u.role === ROLE.CHIEF_ENGINEER
        )
        .map((u) => {
          return {
            ...u,
            nameText: `${u.lastname} ${u.firstname} [${getRoleText(u.role)}]`,
          };
        })
        .sort(sortEngineers)
    );
  },

  async loadClients(context) {
    let responseData = (await companiesClient.getCompanies()).data;

    responseData = responseData.map((c) => {
      return { ...c, nameText: `${c.name}, ${c.district}, ${c.inn}` };
    });

    context.commit("setClients", responseData);
  },

  async createUser(context, data) {
    await usersClient.createUser(data);
  },

  async createClient(context, data) {
    return (await companiesClient.createCompany(data)).data;
  },

  async createTask(context, data) {
    return (await tasksClient.createTask(data)).data;
  },

  async updateClient(context, data) {
    await companiesClient.updateCompany(data);
  },

  async updateUser(context, data) {
    await usersClient.updateUser(data);
  },

  async getAttendanceLog(context, data) {
    return (await usersClient.getAttendanceLog(data)).data;
  },

  async setWorkDays(context, data) {
    await usersClient.setWorkDaysByIds(data);
  },

  async loadEmployeeForClient(context, uid) {
    let responseData = (
      await usersClient.getUsers({
        companyUid: uid,
      })
    ).data;

    responseData = responseData.filter((r) => {
      return r.role === "EMPLOYEE_OF_CLIENT";
    });

    return responseData.map((u) => {
      return {
        ...u,
        nameText: `${u.lastname} ${u.firstname} ${u.phone} ${
          u.post === undefined ? "" : u.post
        }`,
      };
    });
  },

  async loadTechnicForClient(context, uid) {
    const response = (
      await technicsClient.getTechnics({
        companyUid: uid,
        size: 1,
        page: 0,
      })
    ).data;

    return response.map((t) => {
      return {
        ...t,
        techLabel: `${t.technicTypeInfo.name}, ${t.technicProducerInfo.name}, ${t.technicModelInfo.name}, ${t.series}, `,
      };
    });
  },
};
