import { apiConfig } from "@/api/apiConfig";

export default {
  setUserAfterLogin(state, payload) {
    state.token = payload.token;
    //TODO В interceptors
    apiConfig.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${payload.token}`;
    state.refresh_token = payload.refresh_token;
    state.role = payload.roles[0];
    state.username = payload.username;
    state.didAutoLogout = false;
    state.doneAuthTokens = true;
  },
  setUserTokens(state, payload) {
    state.token = payload.token;
    apiConfig.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${payload.token}`;
    state.refresh_token = payload.refresh_token;
    state.didAutoLogout = false;
    state.doneAuthTokens = !!payload.token;
  },
  setUserAfterMe(state, payload) {
    state.role = payload.role;
    state.userData = payload;
    state.id = payload.id;
    state.didAutoLogout = false;
    state.userRegions = payload.regions;
    state.userCompanies = payload?.companies;
  },

  clearUserData(state) {
    state.userData = {};
  },

  setCurrentUserRegions(state, payload) {
    state.currentUserRegions = payload;
  },
  setCurrentTechnicProducers(state, payload) {
    state.currentTechnicProducers = payload;
  },
  setRoleList(state, payload) {
    state.roleList = payload;
  },
  setAutoLogout(state) {
    state.didAutoLogout = true;
  },
  setTaskFilterText(state, payload) {
    state.taskFilterText = payload;
  },
  setUserPermissions(state, payload) {
    state.permissions = payload;
  },
};
