import { getRoleText } from "@/models/roles";

export default {
  token(state) {
    return state.token;
  },
  userData(state) {
    return state.userData;
  },
  doneAuthTokens(state) {
    return state.doneAuthTokens;
  },
  userCompanies(state) {
    return state.userCompanies;
  },
  selectedUserRegions(state) {
    return state.currentUserRegions;
  },
  selectedTechnicProducers(state) {
    return state.currentTechnicProducers;
  },
  userRegions(state) {
    return state.userRegions;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
  getRoleText(state) {
    return getRoleText(state.role);
  },
  getRole(state) {
    return state.role;
  },
  getPermissions(state) {
    return state.permissions;
  },
  getRoleList(state) {
    return state.roleList;
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  },
  getId(state) {
    return state.id;
  },
};
