export default {
  setEmployes(state, payload) {
    state.employes = payload;
  },
  setClients(state, payload) {
    state.clients = payload;
  },
  setEngineers(state, payload) {
    state.engineers = payload;
  },
  setFilteredTasks(state, payload) {
    state.filteredTasks = payload;
  },
  setOnlyEngineers(state, payload) {
    state.onlyEngineers = payload;
  },
};
