export default {
  filtersCount(state) {
    return state.filtersCount;
  },
  config(state) {
    return state.config;
  },
  ptcFlags(state) {
    return state.ptcFlags;
  },
  checkedFlags(state) {
    return state.checkedFlags;
  },
  criticalFlags(state) {
    return state.criticalFlags;
  },
  checklistTemplates(state) {
    return state.checklistTemplates;
  },
  regions(state) {
    return state.regions;
  },
  getRegionById: (state) => (id) => {
    return state.regions.find((region) => region.uid === id);
  },
  countries(state) {
    return state.countries;
  },
  technicTypes(state) {
    return state.technicTypes;
  },
  statuses(state) {
    return state.statuses;
  },
  workTypes(state) {
    return state.workTypes;
  },
  checklistTypes(state) {
    return state.checklistTypes;
  },
  technicModels(state) {
    return state.technicModels;
  },
  technicProducers(state) {
    return state.technicProducers;
  },
  taskFiles(state) {
    return state.taskFiles;
  },
};
