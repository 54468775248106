export const REGIONS = [
  { name: "Барнаул" },
  { name: "Верх-Тула" },
  { name: "Кемерово" },
  { name: "Кокшетау" },
  { name: "Костанай" },
  { name: "Красноярск" },
  { name: "Курган" },
  { name: "Омск" },
  { name: "Павлодар" },
  { name: "Усть-Каменогорск" },
  { name: "ФРЦ" },
]

export const REGIONS_LIST = [
  {
    label: "Омск",
    items: [
      { name: "Азовский немецкий национальный р-н" },
      { name: "Большереченский р-н" },
      { name: "Большеуковский р-н" },
      { name: "Горьковский р-н" },
      { name: "Знаменский р-н" },
      { name: "Исилькульский р-н" },
      { name: "Калачинский р-н" },
      { name: "Колосовский р-н" },
      { name: "Кормиловский р-н" },
      { name: "Крутинский р-н" },
      { name: "Любинский р-н" },
      { name: "Марьяновский р-н" },
      { name: "Москаленский р-н" },
      { name: "Муромцевский р-н" },
      { name: "Называевский р-н" },
      { name: "Нижнеомский р-н" },
      { name: "Нововаршавский р-н" },
      { name: "Одесский р-н" },
      { name: "Оконешниковский р-н" },
      { name: "Омский р-н" },
      { name: "Павлоградский р-н" },
      { name: "Полтавский р-н" },
      { name: "Русско-Полянский р-н" },
      { name: "Саргатский р-н" },
      { name: "Седельниковский р-н" },
      { name: "Таврический р-н" },
      { name: "Тарский р-н" },
      { name: "Тевризский р-н" },
      { name: "Тюкалинский р-н" },
      { name: "Усть-Ишимский р-н" },
      { name: "Черлакский р-н" },
      { name: "Шербакульский р-н" },
    ],
  },
  {
    label: "Новосибирск",
    items: [
      { name: "Баганский р-н" },
      { name: "Барабинский р-н" },
      { name: "Болотнинский р-н" },
      { name: "Венгеровский р-н" },
      { name: "Доволенский р-н" },
      { name: "Здвинский р-н" },
      { name: "Искитимский р-н" },
      { name: "Карасукский р-н" },
      { name: "Каргатский р-н" },
      { name: "Колыванский р-н" },
      { name: "Коченевский р-н" },
      { name: "Кочковский р-н" },
      { name: "Краснозерский р-н" },
      { name: "Куйбышевский р-н" },
      { name: "Купинский р-н" },
      { name: "Кыштовский р-н" },
      { name: "Маслянинский р-н" },
      { name: "Мошковский р-н" },
      { name: "Новосибирский р-н" },
      { name: "Ордынский р-н" },
      { name: "Северный р-н" },
      { name: "Сузунский р-н" },
      { name: "Татарский р-н" },
      { name: "Тогучинский р-н" },
      { name: "Убинский р-н" },
      { name: "Усть-Таркский р-н" },
      { name: "Чановский р-н" },
      { name: "Черепановский р-н" },
      { name: "Чистоозерный р-н" },
      { name: "Чулымский р-н" },
    ],
  },
  {
    label: "Красноярск",
    items: [
      { name: "Абанский р-н" },
      { name: "Ачинский р-н" },
      { name: "Балахтинский р-н" },
      { name: "Березовский р-н" },
      { name: "Бирилюсский р-н" },
      { name: "Боготольский р-н" },
      { name: "Богучанский р-н" },
      { name: "Большемуртинский р-н" },
      { name: "Большеулуйский р-н" },
      { name: "Дзержинский р-н" },
      { name: "Емельяновский р-н" },
      { name: "Енисейский р-н" },
      { name: "Ермаковский р-н" },
      { name: "Идринский р-н" },
      { name: "Иланский р-н" },
      { name: "Ирбейский р-н" },
      { name: "Казачинский р-н" },
      { name: "Канский р-н" },
      { name: "Каратузский р-н" },
      { name: "Кежемский р-н" },
      { name: "Козульский р-н" },
      { name: "Краснотуранский р-н" },
      { name: "Курагинский р-н" },
      { name: "Манский р-н" },
      { name: "Минусинский р-н" },
      { name: "Мотыгинский р-н" },
      { name: "Назаровский р-н" },
      { name: "Нижнеингашский р-н" },
      { name: "Новоселовский р-н" },
      { name: "Партизанский р-н" },
      { name: "Пировский р-н" },
      { name: "Рыбинский р-н" },
      { name: "Саянский р-н" },
      { name: "Северо-Енисейский р-н" },
      { name: "Сухобузимский р-н" },
      { name: "Таймырский Долгано-Ненецкий р-н" },
      { name: "Тасеевский р-н" },
      { name: "Туруханский р-н" },
      { name: "Тюхтетский р-н" },
      { name: "Ужурский р-н" },
      { name: "Уярский р-н" },
      { name: "Шарыповский р-н" },
      { name: "Шушенский р-н" },
      { name: "Эвенкийский р-н" },
      { name: "Таймырский (Долгано-Ненецкий) АО" },
    ],
  },
  {
    label: "Кемерово",
    items: [
      { name: "Беловский р-н" },
      { name: "Гурьевский р-н" },
      { name: "Ижморский р-н" },
      { name: "Кемеровский р-н" },
      { name: "Крапивинский р-н" },
      { name: "Ленинск-Кузнецкий р-н" },
      { name: "Мариинский р-н" },
      { name: "Новокузнецкий р-н" },
      { name: "Прокопьевский р-н" },
      { name: "Промышленновский р-н" },
      { name: "Таштагольский р-н" },
      { name: "Тисульский р-н" },
      { name: "Топкинский р-н" },
      { name: "Тяжинский р-н" },
      { name: "Чебулинский р-н" },
      { name: "Юргинский р-н" },
      { name: "Яйский р-н" },
      { name: "Яшкинский р-н" },
    ],
  },
  {
    label: "Барнаул",
    items: [
      { name: "Алейский р-н" },
      { name: "Алтайский р-н" },
      { name: "Баевский р-н" },
      { name: "Бийский р-н" },
      { name: "Благовещенский р-н" },
      { name: "Бурлинский р-н" },
      { name: "Быстроистокский р-н" },
      { name: "Волчихинский р-н" },
      { name: "Егорьевский р-н" },
      { name: "Ельцовский р-н" },
      { name: "Завьяловский р-н" },
      { name: "Залесовский р-н" },
      { name: "Заринский р-н" },
      { name: "Змеиногорский р-н" },
      { name: "Зональный р-н" },
      { name: "Калманский р-н" },
      { name: "Каменский р-н" },
      { name: "Ключевский р-н" },
      { name: "Косихинский р-н" },
      { name: "Красногорский р-н" },
      { name: "Краснощёковский р-н" },
      { name: "Крутихинский р-н" },
      { name: "Кулундинский р-н" },
      { name: "Курьинский р-н" },
      { name: "Кытмановский р-н" },
      { name: "Локтевский р-н" },
      { name: "Мамонтовский р-н" },
      { name: "Михайловский р-н" },
      { name: "Немецкий Национальный р-н" },
      { name: "Новичихинский р-н" },
      { name: "Павловский р-н" },
      { name: "Панкрушихинский р-н" },
      { name: "Первомайский р-н" },
      { name: "Петропавловский р-н" },
      { name: "Поспелихинский р-н" },
      { name: "Ребрихинский р-н" },
      { name: "Родинский р-н" },
      { name: "Романовский р-н" },
      { name: "Рубцовский р-н" },
      { name: "Славгородский р-н" },
      { name: "Смоленский р-н" },
      { name: "Советский р-н" },
      { name: "Солонешенский р-н" },
      { name: "Солтонский р-н" },
      { name: "Суетский р-н" },
      { name: "Табунский р-н" },
      { name: "Тальменский р-н" },
      { name: "Тогульский р-н" },
      { name: "Топчихинский р-н" },
      { name: "Третьяковский р-н" },
      { name: "Троицкий р-н" },
      { name: "Тюменцевский р-н" },
      { name: "Угловский р-н" },
      { name: "Усть-Калманский р-н" },
      { name: "Усть-Пристанский р-н" },
      { name: "Хабарский р-н" },
      { name: "Целинный р-н" },
      { name: "Чарышский р-н" },
      { name: "Шелаболихинский р-н" },
      { name: "Шипуновский р-н" },
      { name: "Кош-Агачский р-н" },
      { name: "Майминский р-н" },
      { name: "Онгудайский р-н" },
      { name: "Турочакский р-н" },
      { name: "Улаганский р-н" },
      { name: "Усть-Канский р-н" },
      { name: "Усть-Коксинский р-н" },
      { name: "Чемальский р-н" },
      { name: "Чойский р-н" },
      { name: "Шебалинский р-н" },
      { name: "Краснощековский р-н" },
      { name: "Турачакский р-н" },
    ],
  },
  {
    label: "Усть-Каменогорск",
    items: [
      { name: "Усть-Каменогорск Г.А." },
      { name: "Абайский район" },
      { name: "район Аксуат" },
      { name: "Аягозский район" },
      { name: "Зайсанский район" },
      { name: "Курчумский район" },
      { name: "Тарбагатайский район" },
      { name: "Семей Г.А." },
      { name: "Бескарагайский район" },
      { name: "Жарминский район" },
      { name: "Урджарский район" },
      { name: "Кокпектинский район" },
      { name: "район Самар" },
      { name: "Уланский район" },
      { name: "Риддер Г.А." },
      { name: "район Алтай" },
      { name: "Катон-Карагайский район" },
      { name: "Глубоковский район" },
      { name: "Шемонаихинский район" },
      { name: "Бородулихинский район" },
    ],
  },
  {
    label: "Кокшетау",
    items: [
      { name: "Сандыктауский район" },
      { name: "Атбасарский район" },
      { name: "Тайыншинский район" },
      { name: "Аккайнский район" },
      { name: "Кызылжарский район" },
      { name: "Жамбылский район" },
      { name: "район Магжана Жумабаева" },
      { name: "район Шал Акына" },
      { name: "Есильский район" },
      { name: "Тимирязевский район" },
      { name: "Мамлютский район" },
      { name: "Жаксынский район" },
      { name: "Астраханский район" },
      { name: "район Биржан сала" },
      { name: "Есильский район" },
      { name: "Бурабайский район" },
      { name: "Целиноградский район" },
      { name: "район Габита Мусрепова" },
      { name: "Акжарский район" },
      { name: "Уалихановский район" },
      { name: "Аккольский район" },
      { name: "Ерементауский район" },
      { name: "Коргалжинский" },
      { name: "г. Степногорск" },
      { name: "Зерендинский район" },
      { name: "Аршалынский район" },
      { name: "Айыртауский район" },
      { name: "Жаркинский район" },
      { name: "Нуринский район" },
      { name: "Буландинский район" },
      { name: "Шортандинский район" },
      { name: "Егиндыкольский район" },
      { name: "Щербактинский район" },
    ],
  },
  {
    label: "Костанай",
    items: [
      { name: "Алтынсаринский район" },
      { name: "Амангельдинский район" },
      { name: "Аулиекольский район" },
      { name: "Денисовский район" },
      { name: "Джангельдинский район" },
      { name: "Житикаринский район" },
      { name: "Камыстинский район" },
      { name: "Карабалыкский район" },
      { name: "Карасуский район" },
      { name: "Костанайский район" },
      { name: "Мендыкаринский район" },
      { name: "Наурзумский район" },
      { name: "Сарыкольский район" },
      { name: "Район Беимбета Майлина" },
      { name: "Узункольский район" },
      { name: "Фёдоровский район" },
      { name: "Аркалык - город" },
      { name: "Костанай - город" },
      { name: "Лисаковск -город" },
      { name: "Рудный - город" },
    ],
  },
  {
    label: "Павлодар",
    items: [
      { name: "Актогайский район" },
      { name: "Баянаульский район" },
      { name: "Железинский район" },
      { name: "Иртышский район" },
      { name: "район Теренколь" },
      { name: "район Аккулы" },
      { name: "Майский район" },
      { name: "Павлодарский район" },
      { name: "Успенский район" },
      { name: "Щербактинский район" }
    ]
  }
];
