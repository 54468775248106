<template>
  <Dialog
    header="Создание фильтра"
    v-model:visible="displayDialog"
    :modal="true"
    style="width: 50vw"
  >
    <div>
      <h5>Название кнопки</h5>
      <InputText
        :class="buttonName === '' ? 'p-invalid' : ''"
        class="p-col-12 p-mb-1"
        type="text"
        v-model="buttonName"
        maxlength="30"
      />
      <span v-if="buttonName.length === 30" class="p-error">
        Название кнопки не может быть длиннее 30 символов
      </span>

      <span v-if="buttonName.length === 0" class="p-error">
        Введите название кнопки
      </span>

      <h5>Название таблицы</h5>
      <InputText
        class="p-col-12 p-mb-1"
        type="text"
        v-model="tableName"
        maxlength="100"
      />
      <span v-if="tableName.length === 100" class="p-error">
        Название таблицы не может быть длиннее 100 символов
      </span>
    </div>
    <template #footer>
      <Button
        label="Сохранить"
        icon="pi pi-check"
        @click="likeTable"
        autofocus
      />
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="closeDialog"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "LikeDialog",
  emits: ["createNewFilter"],
  data() {
    return {
      buttonName: "",
      tableName: "",
      displayDialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.displayDialog = false;
      this.buttonName = "";
      this.tableName = "";
    },
    likeTable() {
      if (this.buttonName === "") return;
      this.displayDialog = false;
      this.$emit("createNewFilter");
      this.buttonName = "";
      this.tableName = "";
    },
  },
};
</script>

<style scoped></style>
