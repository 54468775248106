import { apiConfig } from "@/api/apiConfig";

export default {
  getCompanies() {
    return apiConfig.get("/companies");
  },

  createCompany(data) {
    return apiConfig.post("/companies", data).catch((error) => {
      throw new Error(error.response.data.message || "Ошибка создания клиента");
    });
  },

  updateCompany(data) {
    return apiConfig.post("/companies", data).catch((error) => {
      throw new Error(
        error.response.data.message || "Ошибка обновления клиента!"
      );
    });
  },
};
