import { apiConfig } from "@/api/apiConfig";

export default {
  createTask(data) {
    return apiConfig.post("/tasks", data).catch((error) => {
      throw new Error(error.response.data.message || "Ошибка создания завяки!");
    });
  },

  updateTask(taskId, data) {
    return apiConfig.put(`/tasks/${taskId}`, data);
  },

  createTaskReport(taskId) {
    return apiConfig.post(`/tasks/${taskId}/create_task_report`);
  },
};
