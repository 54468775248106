export default {
  employes(state) {
    return state.employes;
  },
  clients(state) {
    return state.clients;
  },
  engineers(state) {
    return state.engineers;
  },
  onlyEngineers(state) {
    return state.onlyEngineers;
  },
};
